import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import Contact from '../../../components/contact';
import Hero from '../../../components/hero';
import Layout from '@components/layout';
import MarkdownContent from '../../../components/markdown-content/MarkdownContent';

const Kopare = ({ data }) => {
  const {
    contentfulPage: {
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
    },
  } = data;

  const headLine = data.contentfulPage.headLine;
  const contactHeader = data.contentfulPage.pageContactHeader;
  const { bodyText } = data.contentfulPage.pageContent[0].bodyText;
  return (
    <Layout newStyle>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/allmanna-villkor', name: 'Allmänna Villkor' },
          { path: '/allmanna-villkor/blocket', name: 'Blocket' },
        ]}
        title={pageHeader}
        subtitleMarkdown={pageHeaderDescription}
      />
      <MarkdownContent markdown={bodyText} />
      <Contact headerText={contactHeader} isLargeContact={true} />
    </Layout>
  );
};

export default Kopare;

Kopare.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query KopareQuery {
    contentfulPage(pageUrl: { eq: "/allmanna-villkor/blocket/kopare" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
        internal {
          content
        }
      }
      pageContactHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            bodyText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
